/* global Image */
import React from 'react'
import styled from 'styled-components'
import { media } from '../utils/style-utils'
import axios from 'axios-jsonp-pro'

import mda from '../assets/images/mda@2x.png'
import w3 from '../assets/images/W3.png'
import indigo from '../assets/images/award-indigo-logo@2x.png'
import comm from '../assets/images/comm-awards@2x.png'
import davey from '../assets/images/davey-awards@2x.png'
import ida from '../assets/images/ida-awards@2x.png'

import FloatingLabel from './floatingLabel'
import { ButtonLinkDarkStretch } from './buttonStyles'

const MainFooter = styled.footer`
  padding: 0 20px;
  margin: 50px auto;
  max-width: calc(1480px * 10 / 12);

  ${media.medium`
    margin: 110px auto;
  `}
`
const Subscription = styled.form`
  margin-bottom: 30px;

  ${media.medium`
    width: 40%;
    margin-right: 40px;
    margin-bottom: 70px;
  `}

  ${media.large`
    width: 35%;
    margin-right: calc(20%);
    margin-bottom: 70px;
  `}

  ${media.big`
    width: 40%;
    margin-right: calc(20%);
    margin-bottom: 70px;
  `}

  .input-group {
    display: block;
    margin-right: 30px;
    flex: auto;
  }

  .form-row {
    display: flex;
    position: relative;
  }
`
const OfficeAddress = styled.div`
  font-size: 1.6rem;
  color: #869599;
  line-height: 2.4rem;
  margin-bottom: 30px;
  flex: 1;

  ${media.medium`
    /* width: 15%; */
    margin-right: 40px;
    margin-bottom: 70px;
  `}
`

const SocialLinks = styled.div`
  margin-bottom: 30px;
  flex: 1;
  flex-shrink: 0;

  ${media.medium`
    /* width: 25%; */
    margin-bottom: 70px;
  `}
`

const SocialLink = styled.a`
  margin: 0 7px;

  ${media.large`
    margin: 0 10px;
  `}

  svg {
    margin: 0;
    display: inline-block;

    * {
      transition: fill 0.3s ease-in-out;
    }
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover svg * {
    fill: #bc237d;
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: column;

  ${media.medium`
    flex-direction: row;
    overflow: hidden;
  `}
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Avenir', sans-serif;
  font-size: 1.4rem;
  color: #869599;
  border-top: 1px solid #d8d8d8;
  padding-top: 30px;

  ${media.large`
    padding-top: 50px;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
  `}
`
const ColTitle = styled.h4`
  display: block;
  color: #9f1164;
  margin: 0 0 1.5em 0;
  font-size: 1.8rem;
  letter-spacing: 0.2px;

  span {
    font-weight: 100;
    color: #869599;
  }
`

const FormButton = styled(ButtonLinkDarkStretch)`
  position: relative;
  width: 90px;
  height: 40px;
  padding: 0 !important;
  font-size: 1.8rem !important;
  line-height: 1em;
  bottom: -4px;

  &:hover {
    background: #84004b;
  }
`

const Success = styled.div`
  position: absolute;
  height: calc(100% + 15px);
  padding-top: 15px;
  top: -15px;
  width: 100%;
  background: #fff;
  z-index: 2;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.submitted {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }
`

const Awards = styled.div`
  width: 100%;
  max-width: 460px;
  justify-content: center;
  text-align: center;

  img {
    display: inline-block;
    margin: 0 10px 30px 10px;
  }

  ${media.large`
    max-width:500px;
    text-align:left;
    width:auto;
  `}

  ${media.big`
    max-width:100%;
    img{
      margin: 0 40px 0 0;
    }
  `}
`
const Copyright = styled.div`
  text-align: right;

  ${media.large`
    margin-top:-30px;
  `}
  ${media.big`
    margin-top:0px;
  `}
`

class RetinaImage extends React.Component {
  _isMounted = false

  constructor() {
    super()

    this.state = {
      height: 0,
      width: 0,
    }
  }

  componentDidMount() {
    this._isMounted = true

    var i = new Image()
    i.onload = function () {
      if (this._isMounted) {
        this.setState({
          height: i.height,
          width: i.width,
        })
      }
    }.bind(this)
    i.src = this.props.src
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <img
        {...this.props}
        height={this.state.height / 2}
        width={this.state.width / 2}
        alt={this.props.alt}
      />
    )
  }
}

const AwardLink = (props) => {
  return (
    <a
      className={props.className}
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <RetinaImage src={props.src} alt={props.alt} />
    </a>
  )
}

const AwardLinkStyled = styled(AwardLink)`
  img {
    filter: url(~"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: gray;
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out, filter 0.3s ease-in-out;
  }

  &:hover img {
    opacity: 1;
    filter: none;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
  }
`

class Footer extends React.Component {
  constructor() {
    super()
    this.state = {
      email: '',
    }
  }

  handleEmail(event) {
    let newstate = { ...this.state }
    newstate.email = event.target.value

    this.setState({ ...newstate })
  }

  handleSubmit(event) {
    event.preventDefault()

    axios.jsonp(
      'https://design.us18.list-manage.com/subscribe/post?u=682264e90baafdaff1e6fb432&id=6e5d9615d3&c=?',
      { params: { EMAIL: this.state.email } }
    )

    this.setState({
      submitted: true,
    })
  }

  render() {
    return (
      <MainFooter>
        <Top>
          <Subscription>
            <ColTitle>
              Subscribe{' '}
              <span>to get our latest news, insights and inspiration.</span>{' '}
            </ColTitle>
            <div className="form-row">
              <FloatingLabel
                placeholder="Your email address"
                type="email"
                value={this.state.email}
                onChange={this.handleEmail.bind(this)}
                name="email"
                id="emailfooter"
              />
              <FormButton as="button" onClick={this.handleSubmit.bind(this)}>
                I'm In!
              </FormButton>
              <Success className={this.state.submitted ? 'submitted' : ''}>
                Thanks for signing up, you're in!
              </Success>
            </div>
          </Subscription>
          <OfficeAddress>
            <ColTitle>Pay us a visit</ColTitle>
            231 Harbour Esplanade
            <br />
            Docklands VIC 3008
          </OfficeAddress>
          <SocialLinks>
            <ColTitle>Follow Us</ColTitle>
            <SocialLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/avenuedesign"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Facebook</title>
                <path
                  d="M22 0H2C.895 0 0 .895 0 2v20c0 1.105.895 2 2 2h11v-9h-3v-4h3V8.413c0-3.1 1.893-4.788 4.66-4.788 1.324 0 2.462.1 2.794.143v3.24h-1.918c-1.504 0-1.795.716-1.795 1.764V11h4.44l-1 4h-3.44v9H22c1.105 0 2-.895 2-2V2c0-1.105-.895-2-2-2z"
                  fillRule="nonzero"
                  fill="#AFC2C6"
                />
              </svg>
            </SocialLink>
            <SocialLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/madebyavenue"
            >
              <svg
                width="24"
                height="20"
                viewBox="0 0 24 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Twitter</title>
                <path
                  d="M24 2.6c-.9.4-1.8.7-2.8.8 1-.6 1.8-1.6 2.2-2.7-1 .6-2 1-3.1 1.2-.9-1-2.2-1.6-3.6-1.6-2.7 0-4.9 2.2-4.9 4.9 0 .4 0 .8.1 1.1-4.2-.2-7.8-2.2-10.2-5.2-.5.8-.7 1.6-.7 2.5 0 1.7.9 3.2 2.2 4.1-.8 0-1.6-.2-2.2-.6v.1c0 2.4 1.7 4.4 3.9 4.8-.4.1-.8.2-1.3.2-.3 0-.6 0-.9-.1.6 2 2.4 3.4 4.6 3.4-1.7 1.3-3.8 2.1-6.1 2.1-.4 0-.8 0-1.2-.1 2.2 1.4 4.8 2.2 7.5 2.2 9.1 0 14-7.5 14-14v-.6c1-.7 1.8-1.6 2.5-2.5z"
                  fillRule="nonzero"
                  fill="#AFC2C6"
                />
              </svg>
            </SocialLink>
            <SocialLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/madebyavenue/"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Instagram</title>
                <g fillRule="nonzero" fill="#AFC2C6">
                  <path d="M12 2.162c3.204 0 3.584.012 4.85.07 1.365.062 2.632.336 3.607 1.31.975.976 1.25 2.243 1.31 3.61.06 1.264.07 1.644.07 4.848 0 3.204-.01 3.584-.07 4.85-.06 1.365-.335 2.632-1.31 3.607-.975.975-2.242 1.25-3.608 1.31-1.266.06-1.646.07-4.85.07-3.204 0-3.584-.01-4.85-.07-1.365-.06-2.632-.335-3.607-1.31-.975-.975-1.25-2.242-1.31-3.608-.06-1.266-.07-1.646-.07-4.85 0-3.204.01-3.584.07-4.85.06-1.365.335-2.632 1.31-3.607.975-.975 2.242-1.25 3.608-1.31 1.266-.06 1.646-.07 4.85-.07zM12 0C8.74 0 8.332.014 7.052.072 5.102.162 3.39.64 2.014 2.014.64 3.39.16 5.102.072 7.052.014 8.332 0 8.742 0 12c0 3.26.014 3.668.072 4.948.09 1.95.567 3.663 1.942 5.038 1.375 1.375 3.088 1.853 5.038 1.942 1.28.058 1.69.072 4.948.072 3.26 0 3.668-.014 4.948-.072 1.95-.09 3.663-.567 5.038-1.942 1.375-1.375 1.853-3.088 1.942-5.038.058-1.28.072-1.69.072-4.948 0-3.26-.014-3.668-.072-4.948-.09-1.95-.567-3.663-1.942-5.038C20.61.64 18.898.16 16.948.072 15.668.014 15.258 0 12 0z" />
                  <path d="M12 5.838c-3.403 0-6.162 2.76-6.162 6.162 0 3.403 2.76 6.162 6.162 6.162 3.403 0 6.162-2.76 6.162-6.162 0-3.403-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" />
                  <circle cx="18.406" cy="5.594" r="1.44" />
                </g>
              </svg>
            </SocialLink>
            <SocialLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/avenue"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>LinkedIn</title>
                <path
                  d="M23 0H1C.4 0 0 .4 0 1v22c0 .6.4 1 1 1h22c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM7.1 20.5H3.6V9h3.6v11.5h-.1zM5.3 7.4c-1.1 0-2.1-.9-2.1-2.1 0-1.1.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1 0 1.2-.9 2.1-2.1 2.1zm15.2 13.1h-3.6v-5.6c0-1.3 0-3-1.8-3-1.9 0-2.1 1.4-2.1 2.9v5.7H9.4V9h3.4v1.6c.5-.9 1.6-1.8 3.4-1.8 3.6 0 4.3 2.4 4.3 5.5v6.2z"
                  fillRule="nonzero"
                  fill="#AFC2C6"
                />
              </svg>
            </SocialLink>
            <SocialLink
              target="_blank"
              rel="noopener noreferrer"
              href="http://theinspirationgrid.com/"
            >
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 218.6 218.6"
              >
                <title>Inspiration Grid</title>
                <path
                  d="M212.2 93.8L124.9 6.4c-8.6-8.6-22.5-8.6-31.1 0L6.4 93.8c-8.6 8.6-8.6 22.5 0 31.1l87.3 87.3c8.6 8.6 22.5 8.6 31.1 0l87.3-87.3c8.7-8.6 8.7-22.5.1-31.1zM109.5 26.1l12.7 12.7-12.7 12.7-12.7-12.6 12.7-12.8zm-.6 167.6l-83.4-83.5L75.7 60l12.7 12.8-37.5 37.4 58 58 58.7-58.7L145 86.9l-34 33.9-12.7-12.7L145 61.5l48.1 48-84.2 84.2z"
                  fill="#AFC2C6"
                />
              </svg>
            </SocialLink>
          </SocialLinks>
        </Top>
        <Bottom>
          <Awards>
            <AwardLinkStyled src={w3} alt="w3" url="https://www.w3award.com/" />
            <AwardLinkStyled
              src={comm}
              alt="indigo"
              url="https://www.communicatorawards.com/"
            />
            <AwardLinkStyled
              src={mda}
              alt="drivenxdesign"
              url="https://awards.design/MEL21/"
            />
            <AwardLinkStyled
              src={ida}
              alt="idesignawards"
              url="https://www.idesignawards.com/"
            />
            <AwardLinkStyled
              src={indigo}
              alt="indigo"
              url="https://www.indigoaward.com/"
            />

            <AwardLinkStyled
              src={davey}
              alt="davey awards"
              url="https://www.daveyawards.com/"
            />
          </Awards>
          <Copyright>All work &copy; Avenue and respective owners</Copyright>
        </Bottom>
      </MainFooter>
    )
  }
}

export default Footer
