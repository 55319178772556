import React from 'react'

import '../assets/styles/main.scss'
import '../assets/FoundersFont/stylesheet.css'

import SiteFooter from './siteFooter'

const Layout = ({ children }) => (
  <div className="wrapper">
    {children}
    <SiteFooter />
  </div>
)

export default Layout
